import React from 'react'

function Button() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 27 27">
      <g id="button" transform="translate(-214 -5758)">
        <circle id="Elipse_7" data-name="Elipse 7" cx="13.5" cy="13.5" r="13.5" transform="translate(214 5758)" fill="#9e0059"/>
        <path id="União_2" data-name="União 2" d="M7.723,7.957l3.091-3.049H4.129v.008H0v-1.1H4.129v0h6.684L7.723.769,8.5,0l4.431,4.363L8.5,8.726Z" transform="translate(221.311 5767)" fill="#faf8f0"/>
      </g>
    </svg>
  )
}
export default Button