export const principalText = `
  A partir de 6 anos.
  Podendo ser em grupo ou individualmente os encontros, com datas previamente agendadas.
  Tem uma estrutura inspirada na Antroposofia, com três eixos principais:
`

export const dataCards = [
  {
    id: 'Pensar',
    text: 'Aquisição de conhecimento, técnica.',
  },
  {
    id: 'Sentir',
    text: 'Expressão através da Arte - plásticas, têxteis, som, dança e poesia.',
  },
  {
    id: 'Querer',
    text: 'Mobiliza a vontade e atuação motora própria.',
  },
]