import styled from "styled-components";

export const ContainerHero = styled.section`
  padding: 150px 13%;
  background: #F69D71;

  display: flex;
  flex-direction: column;
  .flex-1 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    .texts {
      font-family: 'Marta', serif;
      font-size: 2rem;
      max-width: 400px;
      color: #FAF8F0;
      margin-left: 5rem;
    }
  }
  .cards {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 5rem;
    .card {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-right: 7rem;
      .circle {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100px;
        height: 100px;
        background: #669bbc;
        border-radius: 50%;
        margin-bottom: 1rem;
        p{
          font-family: 'Marta', serif;
          font-size: 1.2rem;
          color: #FAF8F0;
        }
      } // Circle
      .texto {
        font-family: 'Marta', serif;
        font-size: 1rem;
        width: 150px;
        color: #FAF8F0;
        text-align: center;
      } // Texto
      &:last-child {
        margin-right: 0;
      }
    } // Card
  }// Cards

  @media (max-width: 480px) {
    .flex-1 {
      flex-direction: column;
      img {
        width: 70%;
        margin-bottom: 5rem;
      }
      .texts {
        width: 100%;
        margin-left: 0;
      }
    }
    .cards {
      flex-direction: column;
      .card {
        margin: 0 0 3rem 0;
      }
    }
  }
`