import React from 'react'
// Style
import { ContainerHero } from './style'
import ImgBoneca from '../../../images/ImgBoneca.png'
// Data
import { principalText, dataCards } from './data'

const Introduction = () => {
  return (
    <ContainerHero>
      <div className='flex-1'>
        <img src={ImgBoneca} alt=''/>

        <div className='texts'>
          <p>{principalText}</p>
        </div>
      </div>

      <div className='cards'>
        {dataCards.map(data => (
          <div key={data.id} className='card'>
            <div className='circle'>
              <p>{data.id}</p>
            </div>
            <p className='texto'>{data.text}</p>
          </div>
        ))}
      </div>
    </ContainerHero>
  )
}

export default Introduction