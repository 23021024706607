import React from 'react'
// Components
import * as C from '../components'
import FontStyle from '../fonts/fontStyle'
import BackimageOficina from '../images/imgHero-oficina.png'
// Containers
import * as S from '../containers/Oficinas'

const data = {
  titleI: 'Oficinas',
  titleII: 'Manuais',
  text: 'Os trabalhos manuais são parte deste processo artístico-terapêutico no caminho do auto conhecimento.',
  primaryButton: 'Me inscrever',
}

const Oficinas = () => {
  return (
    <>
      <C.SEO
        title='Oficinas | Atelier Semente'
        description="Os trabalhos manuais são parte deste processo artístico-terapêutico no caminho do auto conhecimento."
      />
      <C.Header/>
      <C.Hero 
        title={data.titleI}
        title2={data.titleII}
        text={data.text}
        primaryButton={data.primaryButton}
        secondButton={data.secondButton}
        image={BackimageOficina}
      />

      <S.Introduction/>
      <S.Theme/>
      <S.Infos/>

      <C.Footer/>
      <FontStyle/>
      <C.GlobalStyles/>
    </>
  )
}

export default Oficinas