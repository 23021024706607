import React, { useState } from 'react'
import { CardWork } from '../../../components'
// Style
import { Container } from './style'
import ImgWorkManuais from '../../../images/ImgWorkManuais.png'
import ImgWorkBonecas from '../../../images/ImgWorkBonecas.png'

const Theme = () => {

  const [manuais, setManuais] = useState(true)
  const [bonecas, setBonecas] = useState(false)

  function handleManuais() {
    setManuais(true)
    setBonecas(false)
  }
  function handleBonecas() {
    setManuais(false)
    setBonecas(true)
  }

  return (
    <Container manuais={manuais} bonecas={bonecas}>
      <h2>Temas das Oficinas</h2>
      <div className='buttons'>
        <button onClick={handleManuais} className='manuais'>{data.manuais.theme}</button>
        <button onClick={handleBonecas} className='bonecas'>{data.bonecas.theme}</button>
      </div>
      { manuais &&
        <CardWork
          img={ImgWorkManuais}
          theme={data.manuais.theme}
          description={data.manuais.description}
          readMore={data.manuais.readMore}
        />
      }
      { bonecas &&
        <CardWork
          img={ImgWorkBonecas}
          theme={data.bonecas.theme}
          description={data.bonecas.description}
          readMore={data.bonecas.readMore}
        />
      }
    </Container>
  )
}

const data = {
  manuais: {
    theme: 'Oficinas Artes Manuais',
    description: 'As Artes Manuais são parte deste processo artístico-terapêutico no caminho da auto-educação e conhecimento de si, na regulação do ritmo interno individual e como papel fundamental na vida social humana. Os trabalhos com fios, suas tramas e amarras, na textura e temperatura, suas cores e tons, chegamos a nossas almas com mais sentido e acolhimento. Trabalhando do ponto de vista ',
    readMore: 'também arteterapêutico , seja infantil ou adulto, no processo de aprendizagem, expressão, autoestima e autoconhecimento. As oficinas de artes manuais são encontros onde iremos trabalhar o processo, desde a iniciação, deste fazer, seja o tricô, crochê, o bordado, o tecer, etc. Tem sua inspiração na pedagogia Waldorf e acontece em uma ambiente acolhedor, sereno e prazeroso. A partir de 6 anos. Os encontros acontecem uma vez por semana. ',
  },
  bonecas: {
    theme: 'Oficinas Confecção de Bonecas(os)',
    description: 'O(a) boneco(a) , além de exercer um papel importante na formação da criança quando vivenciada em seu brincar; no adulto, o seu fazer traz uma vivência terapêutica de como conhecer a estrutura e formação humana, sua constituição anímica-espiritual e espelhamento no processo de autoconhecimento e estima. A oficina de confecção de bonecos(as) de pano tem como ',
    readMore: 'objetivo fortalecer a habilidade manual em construir bonecos, valorizando o brincar infantil saudável, e brinquedos feitos por mãos de um adulto que faz por amor. Vai além, pois este fazer proporciona um processo de autoconhecimento , terapêutico e prazeroso para aquele que o faz. Podemos trabalhar de duas maneiras : em grupo ou individual.  ',
  }
}

export default Theme