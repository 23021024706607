import React from 'react'
// Style
import { Container } from './style'
import { Check } from '../../../Icons'

const Infos = () => {
  return (
    <Container>
        {/* <div className='infos'>
          <h3>{data.title}</h3>
          <p>
            <Check/>
            {data.text}
          </p>
          <p>
            <Check/>
            {data.text2}
          </p>
        </div> */}

      <div className='phrase'>
        <p>{data.phrase}</p>
        <span>Crystiane Melo</span>
      </div>

    </Container>
  )
}


const data = {
  title: 'Informações adicionais:',
  text: 'Em grupo tem uma carga horária de 12 horas, com intervalo para coffee-break  e almoço divididas as horas em um dia e meio ( ex. Sexta-feira a noite e sábado o dia todo).',
  text2: 'A oficina individual acontece em 6 encontros de 2 horas, uma vez por semana.',
  phrase: '"Imagem é um processo, um vir-a-ser contínuo, holístico e integral. Em verdade, imagem é o mundo, a vida e a alma de todas as coisas, que se revela a cada instante. Para reconhecê-la é preciso um “coração vivo”, um “olhar poético”, uma “imaginação desperta”.” ( BERG, 1999, p. 122 )',
}

export default Infos