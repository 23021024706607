import styled from "styled-components";
import { fontSize, color } from "../../../data/dataPatterns";

export const Container = styled.section`
  padding: 150px 13%;
  background: #FAF8F0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  h2 {
    font-family: 'Marta', serif;
    font-weight: lighter;
    font-size: ${fontSize.extraBig};
    color: ${color.primaryColor};
  }
  .buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 60px;
    button {
      width: 160px;
      height: 60px;
      padding: 0 15px;
      background: none;
      margin-right: 20px;
      font-family: unset;
      font-weight: bolder;
      border: 2px solid;
      border-radius: 100px;
      cursor: pointer;
      &:last-child {
        margin-left: 0;
      }
    }
    .manuais {
      color: ${({manuais}) => (manuais ? 'white' : '#fb5607')};
      background: ${({manuais}) => (manuais ? 'rgba(102, 155, 188, 0.7)' : 'transparent')};
      border-color: ${({manuais}) => (manuais ? '#669bbc' : '#fb5607')};
    }
    .bonecas {
      color: ${({bonecas}) => (bonecas ? 'white' : '#fb5607')};
      background: ${({bonecas}) => (bonecas ? 'rgba(102, 155, 188, 0.7)' : 'transparent')};
      border-color: ${({bonecas}) => (bonecas ? '#669bbc' : '#fb5607')};
    }
  }
  
  @media (max-width: 480px) {
    padding: 100px 7%;

    h2 {
      font-size: 3rem;
      text-align: center;
    }
  }
`