import styled from 'styled-components'

export const Container = styled.section`
  padding: 150px 7%;

  .infos {
    margin-bottom: 15rem;
    h3 {
      font-size: 2rem;
      font-family: 'Marta', serif;
      color: #fb5607;
      margin-bottom: 3.5rem;
    }
    p {
      font-family: 'Marta', serif;
      color: #9e0059;
      max-width: 25rem;
      margin-bottom: 1.5rem;
      svg {
        margin-right: 0.5rem;
      }
    }
  } // Infos

  .phrase {
    background: #faf8f0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 100px 25px;
    border-radius: 50px;
    span {
      font-size: 2rem;
      font-family: 'Marta', serif;
      color: #fb5607;
      margin-top: 3rem;
    }
    p {
      font-family: 'Marta', serif;
      font-size: 1.5rem;
      color: #9e0059;
      text-align: center;
      max-width: 65rem;
    }
  }
`